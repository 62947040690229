import { useQuery } from 'react-query';

const axios = require('axios');

const fetchData = async (facilityID) => {
  // const appData = JSON.parse(sessionStorage.getItem('appDataCache'));
  const accessToken = sessionStorage.getItem('accessToken');
  // const { lastFacilityID } = appData.memData;

  const request = await axios.get(
    `${window.app_config.base_api_url}/facilities/${facilityID}/assessments?orderBy="versionSort desc"`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
    }
  );
  if (request.data.entities.length) {
    sessionStorage.setItem('assessmentID', request.data.entities[0].id)
  }

  return request.data.entities;
};

export default function useGetAssessments(facilityID) {
  return useQuery(facilityID && ['assessments', facilityID], () => fetchData(facilityID), { enabled: !!facilityID }
  );
}
