/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './CardTemplate.css';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function CardTemplate({ cardData }) {
  return (
    <div className='col-12 mt-3'>
      <Card>
        <Card.Header>{cardData.title}</Card.Header>
        <Card.Body>
          <div className='row'>
            <div className='col-4 quality-border'>
              <p className='fw-bold'>
                Score
              </p>
              <p style={{ backgroundColor: `#${cardData.scoreColor}`, borderRadius: '4px', paddingLeft: '5px' }}>{cardData.score}</p>
              <p className='fw-bold'>
                Measurement Ability
              </p>
              <p>{cardData.measurementAbility}</p>
              <p className='fw-bold'>
                Preformance perception
              </p>
              <p>{cardData.performancePerception}</p>
            </div>
            <div className='col-8'>
              <img className='test-graph-width' src={cardData.imageURL} alt='test' />
            </div>
          </div>

        </Card.Body>
        <Card.Footer>
          <Accordion flush>
            <Accordion.Item eventKey={cardData.title}>
              <Accordion.Header>
                <div className='col-4'>
                  {cardData.formula}
                </div>
                <div className='col-8 text-center'>
                  {cardData.dateRange}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {cardData.notes}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default CardTemplate;
