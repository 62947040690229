/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './TableTemplate.css';
import Table from 'react-bootstrap/Table';

function TableTemplate({ headers, rows, opts, x }) {
  console.log('headers', headers);
  console.log('rows', rows);

  const renderHeaderColumns = (header) => (
    <th>{header}</th>
  )

  const renderTableRows = (xrows) => (
    // xrows.row.map((ourRow) => {
    //   console.log(ourRow.value);
    // });
    xrows.row.map((ourRow) => (
      <td style={{ backgroundColor: `${ourRow.color}` }}>{ourRow.value}</td>
    ))
  )

  return (
    <div className='col-12' style={{ maxWidth: `${opts.width}%` }}>
      <Table bordered hover variant='light' key={x}>
        <thead>
          <tr>
            {headers?.map((header) => renderHeaderColumns(header))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => <tr>{renderTableRows(row)}</tr>)}
        </tbody>
      </Table>
    </div>
  );
}

export default TableTemplate;
