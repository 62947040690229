/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import './Header.css';

const Header = (props) => (
  <div className='row header'>
    <div className='col-10'>
      <h2 className='header-title border-bottom'>{props.header}</h2>
      <div dangerouslySetInnerHTML={{ __html: props.desc }} />
    </div>
  </div>
)
export default Header
