/* eslint-disable import/no-cycle */
import React from 'react';
import './Home.css';
import { Switch, Route, useLocation } from 'react-router-dom';
import {
  useQueryClient,
} from 'react-query'
import Login from './Login';
import ExecSummary from './ExecSummary'
import DigitalScale from './DigitalScale'
import Quality from './Quality'
import Assessment from './Assessment'
import Intro from './Intro'
import Staffing from './Staffing'
import Productivity from './Productivity'
import Inventory from './Inventory'
import Spend from './Spend'
import DataIT from './DataIT'
import Operations from './Operations'
import SideNav from './SideNav';
import TopBar from './TopBar';
import fetchUserInfo from '../hooks/fetchUserInfo';
import useGetAssessments from '../hooks/useGetAssessments'

// eslint-disable-next-line react/prop-types
const Home = ({ props }) => {
  const { isSuccess, data, error, isFetching } = fetchUserInfo();
  const queryClient = useQueryClient();
  const userInfo = queryClient.getQueryData('userInfo');
  const facilityID = data?.primaryFacilityID;
  const { isIdle, data: assessments } = useGetAssessments(facilityID);
  return (
    <div id='wrapper'>
      <SideNav isFetching={isFetching} error={error} userInfo={userInfo} />
      <div id='page-wrapper'>
        <TopBar />
        <Switch>
          <Route path='/executive-summary' component={ExecSummary} />
          <Route path='/digital-scale' component={DigitalScale} />
          <Route path='/quality' component={Quality} />
          <Route path='/assessment' component={Assessment} />
          <Route path='/intro' component={Intro} />
          <Route path='/staffing' component={Staffing} />
          <Route path='/productivity' component={Productivity} />
          <Route path='/inventory' component={Inventory} />
          <Route path='/spend' component={Spend} />
          <Route path='/datait' component={DataIT} />
          <Route path='/operations' component={Operations} />
          {/* <Route path='/messages' component={Messages} />
            <Redirect to='/' /> */}
        </Switch>
      </div>
    </div>
  );
}

export default Home;
