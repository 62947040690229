import React from 'react';
import Image from 'react-bootstrap/Image';
import {
  useQueryClient,
} from 'react-query';
import Header from './Header';
import SubHeader from './SubHeader';
import TableTemplate from './TableTemplate'
import CardTemplate from './CardTemplate';
import './ExecSummary.css';
import useGetAssessmentByID from '../hooks/useGetAssessmentByID';

const Inventory = () => {
  const queryClient = useQueryClient();
  const userInfo = queryClient.getQueryData('userInfo');
  const assessmentID = sessionStorage.getItem('assessmentID');
  const { data: UIArray, isIdle, isFetching, error } = useGetAssessmentByID(assessmentID, 'metrics_inventory');

  const renderHeader = (ui) => {
    if (ui.type === 'header') {
      return (
        <Header
          header={ui.data.title}
          desc={ui.data.text}
          key={ui.id}
        />
      );
    }
    return '';
  }
  const renderUI = (ui, i) => {
    if (ui.type === 'card') {
      return (
        <CardTemplate
          cardData={ui.data}
          key={ui.id}
        />
      )
    }
    if (ui.type === 'image') {
      return (
        <div className='col-12'>
          <div style={{ width: `${ui.data.opts.width}%` }} className=''>
            <Image key={ui.id} className='image-container' src={ui.data.iamgeURL} fluid />
          </div>
        </div>
      );
    }
    if (ui.type === 'table') {
      return (
        <TableTemplate
          headers={ui.data.headers}
          rows={ui.data.rows}
          key={ui.id}
          opts={ui.data.opts}
        />

      );
    }
    if (ui.type === 'subHeader') {
      console.log('index', i)
      return (
        <SubHeader
          title={ui.data.title}
          text={ui.data.text}
          key={ui.id}
          index={i}
        />
      )
    }
    return '';
  }

  return (
    <>
      {UIArray?.map((ui) => renderHeader(ui))}
      <div className='exec-summary'>
        {UIArray?.map((ui, i) => renderUI(ui, i))}
      </div>
    </>
  );
}
export default Inventory
