import { useQuery } from 'react-query';

const axios = require('axios');

const fetchData = async (passValue) => {
  // const appData = JSON.parse(sessionStorage.getItem('appDataCache'));
  const accessToken = sessionStorage.getItem('accessToken');
  // const { lastFacilityID } = appData.memData;

  const request = await axios.post(
    `${window.app_config.base_api_url}/users/getMyUserInfo?isMethod="true"`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return request.data;
};

export default function fetchAuthToken() {
  return useQuery(['userInfo'], () => fetchData()
  );
}
