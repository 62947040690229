/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
// import './SideNav.css';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import { MdAssessment, MdSummarize } from 'react-icons/md';
import { FaBalanceScale } from 'react-icons/fa';
import { BsGraphUp } from 'react-icons/bs';
import 'react-pro-sidebar/dist/css/styles.css';
import './SideNav.css';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  useQueryClient,
} from 'react-query';
import fetchUserInfo from '../hooks/fetchUserInfo';
import useGetAssessments from '../hooks/useGetAssessments';
import stock from '../images/stock.jpeg';

const SideNav = ({ isFetching, error, userInfo }) => {
  const location = useLocation();
  return (
    <ProSidebar>
      <SidebarHeader>
        <div className='nav-profile'>
          <img src={isFetching || error || (userInfo && userInfo.picURI === '') ? stock : userInfo && userInfo.picURI} alt='mike' className='mike rounded-circle' />
          <div className='profile-details float-right'>
            <h6 className='font-weight-bold'>{isFetching ? '...' : error ? 'N/A' : userInfo && userInfo.primaryFacilityName}</h6>
            <p>Assessment</p>
          </div>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu innerSubMenuArrows={false} iconShape='circle'>
          <MenuItem active={location.pathname === '/executive-summary'} icon={<MdSummarize size='1.3em' />}>
            Exec Summary
            <Link to='/executive-summary' />
          </MenuItem>
          <MenuItem active={location.pathname === '/digital-scale'} icon={<FaBalanceScale size='1.3em' />}>
            Digital Scale
            <Link to='/digital-scale' />
          </MenuItem>
          <MenuItem active={location.pathname === '/assessment'} icon={<MdAssessment size='1.3em' />}>
            Assessment
            <Link to='/assessment' />
          </MenuItem>
          <SubMenu 
            icon={<BsGraphUp size='1.3em' />} 
            title='Metrics'
            defaultOpen
          >
            <MenuItem active={location.pathname === '/intro'}>
              Intro
              <Link to='/intro' />
            </MenuItem>
            <MenuItem active={location.pathname === '/quality'}>
              Quality
              <Link to='/quality' />
            </MenuItem>
            <MenuItem active={location.pathname === '/staffing'}>
              Staffing
              <Link to='/staffing' />
            </MenuItem>
            <MenuItem active={location.pathname === '/productivity'}>
              Productivity
              <Link to='/productivity' />
            </MenuItem>
            <MenuItem active={location.pathname === '/inventory'}>
              Inventory
              <Link to='/inventory' />
            </MenuItem>
            <MenuItem active={location.pathname === '/spend'}>
              Spend
              <Link to='/spend' />
            </MenuItem>
            <MenuItem active={location.pathname === '/datait'}>
              Data/IT
              <Link to='/datait' />
            </MenuItem>
            <MenuItem active={location.pathname === '/operations'}>
              Operations
              <Link to='/operations' />
            </MenuItem>
          </SubMenu>
        </Menu>
      </SidebarContent>
      {/* <SidebarFooter>
        <h4>end of sidebar</h4>
      </SidebarFooter> */}
    </ProSidebar>
  );
}

export default SideNav;
