/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './TopBar.css'
import { MdLogout } from 'react-icons/md';
import { Redirect } from 'react-router-dom';

const Topbar = () => {
  const [logout, setLogout] = useState(false);

  return (
    <div className='row border-bottom topbar'>
      {logout && <Redirect to='/login' />}
      <nav className='navbar navbar-static-top'>
        <div className='everest fw-bold'>
          {/* <h4>Everest</h4> */}
        </div>     
        <div className='logout-link float-right' onClick={() => { setLogout(true); }}>
          <span className='fw-bold'>
            <MdLogout title='Log out' size='1.3em' />
            &nbsp;
            Log out
          </span>
        </div>
      </nav>
    </div>
  );
};

export default Topbar;
