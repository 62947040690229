/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import './Header.css';
import './SubHeader.css';

const SubHeader = (props) => (
  <div className={props.index && props.index === 1 ? 'mb-1' : 'subheader-container mb-1'}>
    <div className='col-8'>
      <div className='subheader'>
        <h2 className='subheader-title'>{props.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: props.text }} />
      </div>
    </div>
  </div>
)
export default SubHeader
