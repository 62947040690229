/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/react-in-jsx-scope */
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query'
import { Switch, Route, useLocation } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools'
import Home from './components/Home'
import Login from './components/Login'
 
const oneHourInMS = 1000 * 60 * 1
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: true,
      retry: true,
      staleTime: oneHourInMS,
    },
  },
});

function App() {
  const location = useLocation(); 
  // console.log('location', location)
  return (
    <div className='App'>
      <QueryClientProvider client={queryClient}>
        {location.pathname === '/login' || location.pathname === '/'
          ? (
            <Login />
          )
          : <Home />}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
