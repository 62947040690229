import { useQuery, useQueryClient } from 'react-query';

const axios = require('axios');

const fetchReports = async (queryClient, id, page) => {
  const accessToken = sessionStorage.getItem('accessToken');

  const request = await axios.get(
    `${window.app_config.base_api_url}/assessments/${id}/assessmentContents?filter="page = '${page}'"&orderBy="sortOrder asc"`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return request.data.entities;
};

export default function useGetAssessmentByID(id, page) {
  const queryClient = useQueryClient();
  return useQuery(id && ['assessment', id, page], () => fetchReports(queryClient, id, page), {
    enabled: !!id,
    staleTime: Infinity,
  });
}
